<template>
  <div>
    <b-card>
      <div>
        <!-- Table Fitler -->
        <div>
          <b-row class="my-1">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <b-form-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                size="sm"
                class="per-page-selector d-inline-block mx-50"
                style="width: 100px;"
              />
            </b-col>
            <!-- Search -->
            <b-col>
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="searchFilter"
                  type="search"
                  placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  style="color: #99dff1"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!searchFilter"
                    @click="searchFilter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <!-- table content -->
        <div>
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            style="text-align: center; font-size: small"
            head-variant="light"
            :items="paginatedItems"
            :fields="branchFields"
          >
            <template #thead-top>
              <b-tr>
                <!-- 일반적인 헤더 컬럼 -->
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  No.
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  권한
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  아이디/닉네임
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  상위
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  통합캐쉬
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  포인트
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  루징
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  롤링(스포츠)
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  롤링(카지노)
                </b-th>
                <b-th
                  colspan="2"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  롤링(슬롯)
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  회원수
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  회원
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  정산
                </b-th>
                <b-th
                  rowspan="2"
                  class="align-middle"
                >
                  수정
                </b-th>
              </b-tr>
              <b-tr>
                <!-- 병합된 헤더 아래의 컬럼들 -->
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  요율(%)
                </b-th>
                <b-th>상위(%)</b-th>
              </b-tr>
            </template>
            <!-- Column: No -->
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(role)="data">
              <b-media class="align-content-center">
                <template>
                  <b-avatar
                    size="32"
                    :src="data.item.role"
                    :text="avatarText(data.item.role)"
                    :variant="`${resolveUserRoleVariant(data.item.role)}`"
                  />
                </template>
              </b-media>
            </template>
            <template #cell(userid)="data">
              <div
                class="text-success font-weight-bold d-block text-nowrap"
                style="text-align: left !important; min-width: 7rem; text-decoration: underline; text-underline-offset: 5px;"
                @click="userClicked(data)"
              >
                {{ data.item.userid }}
              </div>
              <div
                class=""
                style="text-align: left !important; min-width: 7rem; text-underline-offset: 5px;"
              >
                {{ data.item.nickname }}
              </div>
            </template>
            <template #cell(userCount)="data">
              <div
                class="text-left"
                style="min-width: 6rem"
              >
                <div v-html="formatHtmlTotalCount(data.item)" />
              </div>
            </template>
            <template #cell(rate)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.rate) }}
              </span>
            </template>
            <template #cell(upperRate)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.upperRate) }}
              </span>
            </template>
            <template #cell(rateSportRolling)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.rateSportRolling) }}
              </span>
            </template>
            <template #cell(upperRateSportRolling)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.upperRateSportRolling) }}
              </span>
            </template>
            <template #cell(rateCasinoRolling)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.rateCasinoRolling) }}
              </span>
            </template>
            <template #cell(upperRateCasinoRolling)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.upperRateCasinoRolling) }}
              </span>
            </template>
            <template #cell(rateSlotRolling)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.rateSlotRolling) }}
              </span>
            </template>
            <template #cell(upperRateSlotRolling)="data">
              <span style="color: rgb(229,166,48)">
                {{ formatRate(data.item.upperRateSlotRolling) }}
              </span>
            </template>

            <template #cell(isShowUsers)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                size="sm"
                @click="btnShowUsers(data.item)"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="14"
                />
              </b-button>
            </template>
            <template #cell(isShowBalance)="data">
              <b-button
                v-if="data.item.nickname !== userData.nickname"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                size="sm"
                @click="btnShowBalance(data.item)"
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="14"
                />
              </b-button>
            </template>
            <template #cell(edit)="data">
              <b-button
                v-if="data.item.nickname !== userData.nickname"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                size="sm"
                @click="btnEdit(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="14"
                />
              </b-button>
            </template>
          </b-table>
        </div>
        <!-- table footer -->
        <div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
              style="margin-bottom: 0.5rem;"
            >
              <span
                class="font-small-2"
              >{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <!-- Modal -->
    <template>
      <b-modal
        id="showModalDetatil"
        v-model="isShowModalDetatil"
        no-close-on-backdrop
        centered
        hide-footer
        size="lg"
        title="회원 조회"
      >
        <!-- Modal Header -->
        <template #modal-header>
          <h5 class="modal-title">
            회원보기
          </h5>
          <div class="modal-actions">
            <feather-icon
              icon="XIcon"
              class="ml-1 cursor-pointer"
              @click="$bvModal.hide('showModalDetatil')"
            />
          </div>
        </template>

        <!-- Modal Body -->
        <template #default>
          <div>
            <b-row>
              <b-col>
                <b-table
                  responsive
                  show-empty
                  hover
                  small
                  empty-text="No matching records found"
                  style="text-align: center; font-size: small"
                  :items="isShowModalDetatilItems"
                  :fields="userFields"
                >
                  <!-- Column: No -->
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>
                  <!-- Column: Role -->
                  <template #cell(role)="data">
                    <b-media class="align-content-center">
                      <template>
                        <b-avatar
                          size="32"
                          :src="data.item.role"
                          :text="avatarText(data.item.role)"
                          :variant="`${resolveUserRoleVariant(data.item.role)}`"
                        />
                      </template>
                    </b-media>
                  </template>
                  <!-- Column: Userid / nickname -->
                  <template #cell(userid)="data">
                    <div
                      @click="rowClicked(data.item)"
                    >
                      <div
                        class="text-success font-weight-bold d-block text-nowrap"
                        style="text-align: left !important; min-width: 7rem; text-decoration: underline; text-underline-offset: 5px;"
                      >
                        {{ data.item.userid }}
                      </div>
                      <div
                        class=""
                        style="text-align: left !important; min-width: 7rem; text-underline-offset: 5px;"
                      >
                        {{ data.item.nickname }}
                      </div>
                    </div>
                  </template>
                  <!-- Column: cash -->
                  <template #cell(cash)="data">
                    <div
                      class="text-right"
                      style="color:rgb(196,186,138); min-width: 5rem"
                    >
                      {{ Number(data.item.cash).toLocaleString() }}
                    </div>
                  </template>
                  <!-- Column: point -->
                  <template #cell(point)="data">
                    <div
                      class="text-right"
                      style="color:rgb(196,186,138); min-width: 5rem"
                    >
                      {{ Number(data.item.point).toLocaleString() }}
                    </div>
                  </template>
                  <!-- Column: betTotal -->
                  <template #cell(betTotal)="data">
                    <div
                      class="text-right"
                      style="color:rgb(196,186,138); min-width: 5rem"
                    >
                      {{ Number(data.item.betTotal).toLocaleString() }}
                    </div>
                  </template>
                  <!-- Column: depositTotal -->
                  <template #cell(depositTotal)="data">
                    <div
                      class="text-right"
                      style="color:rgb(196,186,138); min-width: 5rem"
                    >
                      {{ Number(data.item.depositTotal).toLocaleString() }}
                    </div>
                  </template>
                  <!-- Column: exchangeTotal -->
                  <template #cell(exchangeTotal)="data">
                    <div
                      class="text-right"
                      style="color:rgb(196,186,138); min-width: 5rem"
                    >
                      {{ Number(data.item.exchangeTotal).toLocaleString() }}
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row
              v-if="true"
            >
              <b-col cols="12">
                <b-button
                  v-if="false"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  size="sm"
                  class="float-right"
                  @click.prevent="btnSave"
                >
                  저장
                </b-button>
                <b-button
                  ref="reset"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="reset"
                  variant="primary"
                  size="sm"
                  class="float-right mr-2"
                  @click="$bvModal.hide('showModalDetatil')"
                >
                  닫기
                </b-button>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-modal>
    </template><!-- .Modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect, BButton,
  BTable, BTr, BTh, BPagination,

  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { FETCH_BRANCH_ORGANIZATION_CHART_SUB } from '@/store/branch/action'
import { FETCH_USERS } from '@/store/users/action'

import { createNamespacedHelpers } from 'vuex'

import { avatarText } from '@core/utils/filter'
import router from '@/router'

const branchStore = createNamespacedHelpers('branchStore')
const userStore = createNamespacedHelpers('userStore')

export default {
  name: 'BranchOragnizationChartSub',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BTable,
    BTr,
    BTh,
    BPagination,

    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      avatarText,

      // TableFilter
      searchFilter: '',

      // TableOption
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)

      // Tablefields
      branchFields: [
        { key: 'no', label: 'No.', thClass: 'd-none' },
        { key: 'id', sortable: false, label: '번호', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'role', sortable: false, label: '권한', thClass: 'd-none' },
        { key: 'userid', label: '아이디/닉네임', thClass: 'd-none' },
        { key: 'recommendNickname', label: '상위', thClass: 'd-none' },
        { key: 'cash', sortable: false, label: '통합캐쉬', thClass: 'd-none', formatter: 'formatNumberWithCommas' },
        { key: 'point', sortable: false, label: '포인트', thClass: 'd-none', formatter: 'formatNumberWithCommas' },
        { key: 'rate', label: '비율', thClass: 'd-none', tdClass: 'text-rating' },
        { key: 'upperRate', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSportRolling', label: '비율', thClass: 'd-none', tdClass: 'text-rating' },
        { key: 'upperRateSportRolling', label: '상위', thClass: 'd-none' },
        { key: 'rateCasinoRolling', label: '비율', thClass: 'd-none', tdClass: 'text-rating' },
        { key: 'upperRateCasinoRolling', label: '상위 비율', thClass: 'd-none' },
        { key: 'rateSlotRolling', label: '비율', thClass: 'd-none', tdClass: 'text-rating' },
        { key: 'upperRateSlotRolling', label: '상위 비율', thClass: 'd-none' },
        { key: 'userCount', label: '회원수', thClass: 'd-none' },
        { key: 'isShowUsers', label: '회원보기', thClass: 'd-none' },
        { key: 'isShowBalance', label: '정산보기', thClass: 'd-none' },
        { key: 'edit', label: '총판수정', thClass: 'd-none', formatter: value => (value == null ? '미설정' : value) },
      ],
      userFields: [
        { key: 'index', label: 'No.' },
        { key: 'id', label: '번호', thClass: 'd-none', tdClass: 'd-none' },
        { key: 'role', label: '권한' },
        { key: 'userid', label: '아이디/닉네임' },
        { key: 'recommendNickname', label: '상위' },
        { key: 'rank', label: '등급' },
        { key: 'cash', label: '통합캐쉬' },
        { key: 'point', label: '포인트' },
        { key: 'betTotal', label: '총배팅금' },
        { key: 'depositTotal', label: '총충전금' },
        { key: 'exchangeTotal', label: '총환전금' },
      ],

      // TableModal
      isShowModalDetatil: false,
      isShowModalDetatilItems: [],

    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...branchStore.mapGetters({
      fetchBranchOrganizationChartSub: 'fetchBranchOrganizationChartSub',
    }),
    ...userStore.mapGetters({
      fetchUsers: 'fetchUsers',
    }),

    // Table filtered Items
    filteredItems() {
      let filteredItems = []
      if (this.fetchBranchOrganizationChartSub.length) {
        const { children: childNode = [], ...rootNode } = this.fetchBranchOrganizationChartSub[0]
        filteredItems = [rootNode, ...childNode.filter(item => item.role !== 'user')]
      }
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      return filteredItems
    },
    // Table filtered Items => Table Pagination Items
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const paginatedData = this.filteredItems.slice(start, end)

      this.totalRows = this.filteredItems.length
      this.pageFrom = start + 1
      this.pageTo = start + paginatedData.length
      this.pageOf = this.totalRows

      return paginatedData
    },
    // filteredUsers() {
    //   let filteredItems = []
    //   if (this.fetchUsers.length) {
    //     filteredItems = this.fetchUsers.filter(item => item.role === 'user')
    //   }
    //   return filteredItems
    // },
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchOrganizationChartSub: FETCH_BRANCH_ORGANIZATION_CHART_SUB,
    }),
    ...userStore.mapActions({
      $fetchUsers: FETCH_USERS,
    }),

    /* [formatter] */
    formatRate(value) {
      return value !== null ? value : '-'
    },
    formatNumberWithCommas(value) {
      if (value == null) {
        return ''
      }
      return parseInt(value, 10).toLocaleString()
    },

    formatHtmlTotalCount(data) {
      if (!data || !data.totalCount) {
        return ''
      }
      const userCount = data.totalCount.userCount || 0
      const branchCount = data.totalCount.branchCount || 0
      const totalCount = userCount + branchCount

      return `<div
                class="text-center"
                style="min-width: 6rem"
              >
              ${totalCount} 명 (관리자: ${branchCount} 명 / 유저: ${userCount} 명)
              </div>`
    },

    rowClicked(item) {
      // 기존 창에서 열기
      // localStorage.setItem('userView', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }))
      // router.push({ name: 'apps-user-view' })
      // 새로운 창에서 열기
      // localStorage.setItem('userView', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }))
      // this.$store.commit('rootStore/SET_USER_VIEW', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }), { root: true })

      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        userid: item.userid,
        site: item.site,
        nickname: item.nickname,
      }
      const url = router.resolve({
        name: 'apps-user-view',
        query: queryParams,
      }).href
      // const url = router.resolve({ name: 'apps-user-view' }).href
      // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions) // 매번 다른 이름으로 팝업 창 열기
    },
    async btnShowUsers(data) {
      await this.$fetchUsers({
        site: this.siteSelected,
        userid: data.userid,
      })
      this.isShowModalDetatil = true
      console.log(data)
      this.isShowModalDetatilItems = this.fetchUsers.filter(item => item.recommendNickname === data.nickname && item.role === 'user')
    },
    async btnShowBalance(data) {
      // await this.$fetchUsers({
      //   site: this.siteSelected,
      //   userid: data.userid,
      // })
    },
    btnEdit(data) {
      // console.log(data.userid)
      this.$router.push({
        name: 'branch-create',
        params: { site: data.site, userid: data.userid },
      })
    },
    // Modal
    btnModal(target) {
      if (target === 'show') {
        console.log(this.componentName, 'btnModal()', target)
        // this.init()
      }
      if (target === 'close') {
        // console.log(this.componentName, 'btnModal()', target)
        this.btnReset()
        this.$bvModal.hide(this.componentName)
        if (!this.isSelected) {
          this.$router.go(-1)
        }
      }
    },

    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
    userClicked(data) {
      // localStorage.setItem('userView', JSON.stringify({ userid: data.item.userid, site: data.item.site, nickname: data.item.nickname }))
      // this.$store.commit('rootStore/SET_USER_VIEW', JSON.stringify({ userid: data.item.userid, site: data.item.site, nickname: data.item.nickname }))

      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        userid: data.item.userid,
        site: data.item.site,
        nickname: data.item.nickname,
      }

      const url = router.resolve({
        name: 'apps-user-view',
        query: queryParams,
      }).href
      // const url = router.resolve({ name: 'apps-user-view' }).href
      // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions) // 매번 다른 이름으로 팝업 창 열기
    },
  },
}
</script>
<style scoped>
.b-table td.text-rating {
  color: rgb(229,166,48) !important;
}
</style>
